import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseUrl } from "src/app/framework/constants/url-constants";

@Injectable({
  providedIn: 'root'
})
export class CuriousLearnersService {

  constructor(private readonly http: HttpClient) { }

  getCuriousLearnersDetails(id) {
    const href = `${BaseUrl.USER}/curiousLearner/find/${id}`;
    return this.http.get<any>(href);
  }

  findAllPaginated(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/curiousLearner/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  findAllHistoricalLerners(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/curiousLearner/searchHistoricLerner`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  createLearnerEnrolment(payload) {
    const href = `${BaseUrl.LEARNER_QUALIFICATION}/enrolment/createEnrolment`;
    return this.http.post<any>(href, payload);
  }

  updateLearnerEnrolment(payload) {
    const href = `${BaseUrl.LEARNER_QUALIFICATION}/enrolment/`;
    return this.http.put<any>(href, payload);
  }

  findAllEnrolments(suId, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.LEARNER_QUALIFICATION}/enrolment/${suId}`;
    return this.http.post<any>(href, body,{
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString()),
    });
  }

  getTransferLearnerHistory(id):Observable<any> {
    const href = `${BaseUrl.USER}/curiousLearner/userTransfer/${id}`;
    return this.http.get<any>(href);
  }

  getEnrolmentDetails(learnerId, enrolmentId): Observable<any> {
    const href = `${BaseUrl.LEARNER_QUALIFICATION}/enrolment/${learnerId}/${enrolmentId}`;
    return this.http.get<any>(href);
  }

  getRefDataByIdentifier(identifier: String) {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/ref-data-choice/refDataIdentifier/${identifier}`;
    return this.http.get<any>(href);
  }

  createAlnEligibility(payload) {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility`;
    return this.http.post<any>(href, payload);
  }

  createESOLEligibility(payload) {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility/esol`;
    return this.http.post<any>(href, payload);
  }

  getAlnDetails(learnerId): Observable<any> {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility/aln/${learnerId}`;
    return this.http.get<any>(href);
  }

  getLearnerEligibilities(learnerId): Observable<any> {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility/status/${learnerId}`;
    return this.http.get<any>(href);
  }

  getAllRefData(){
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/ref-data-choice/all`;
    return this.http.get<any>(href);
  }

  getFunctionalSkillDetails(learnerId): Observable<any> {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility/fs/${learnerId}`;
    return this.http.get<any>(href);
  }

  createFsEligibility(payload) {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility/functionalSkills`;
    return this.http.post<any>(href, payload);
  }
  
  getEsolDetails(learnerId): Observable<any> {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility/esol/${learnerId}`;
    return this.http.get<any>(href);
  }
  
  createReadingEligibility(payload) {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility/reading`;
    return this.http.post<any>(href, payload);
  }

  getReadingDetails(learnerId): Observable<any> {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/eligibility/reading/${learnerId}`;
    return this.http.get<any>(href);
  }

  getAssessmentListDetails(learnerId): Observable<any> {
    const href = `${BaseUrl.LEARNER_ASSESSMENT}/assessments/${learnerId}`;
    return this.http.get<any>(href);
  }

  

}
