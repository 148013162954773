import { Component, OnInit } from '@angular/core';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { FinanceNavigation } from './finance-nav';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent implements OnInit {

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly financeNavigation: FinanceNavigation,
  ) { 
    this.inPageNavService.setNavItems(this.financeNavigation.financeSubMenu);
  }
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

}
