import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs/operators';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { InPageNavService } from '../shared/components/in-page-nav/in-page-nav.service';
import { Observable} from 'rxjs';
import { CuriousLearnersService } from './curious-learners.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SessionsService } from 'src/app/sessions/sessions.service';
import { Router } from '@angular/router';

export interface ExampleTab {
  label: string;
}

@Component({
  selector: 'app-curious-learners',
  templateUrl: './curious-learners.component.html',
  styleUrls: ['./curious-learners.component.scss'],
})
export class CuriousLearnersComponent implements OnInit, AfterViewInit {

  asyncTabs: Observable<ExampleTab[]>;

  dataSource: any = new MatTableDataSource<any>();
  displayedColumns: string[] = ['DateofBirth', 'firstName', 'lastName', 'ULN', 'actions'];
  displayedHistoricColumns: string[] = ['DateofBirth', 'firstName', 'lastName', 'ULN', 'establishmentId', 'actions'];
  pageSize = 10;
  filterBy = { 'keyword': '' }
  sortColumn = 'firstName';
  sortDirection = 'asc';
  showcaseLoad: boolean = false;
  presentLeraner: string;
  cLearner: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly router: Router,
    private readonly snackBarService: SnackBarService,
    private readonly sessionService: SessionsService,
    private readonly curiousLearnersService: CuriousLearnersService) {
  }

  ngOnInit(): void {
    this.getCurrentLerners(this.filterBy.keyword);
    // this.getCurrentLernersdata(this.filterBy);

  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(data => {
      this.sortColumn = data.active;
      this.sortDirection = data.direction;
      this.paginator.pageIndex = 0;
      //this.getCurrentLerners(this.filterBy.keyword);
      this.handlePage();
      this.showcaseLoad = false;
    });

    setTimeout(() => {
      this.paginator.page
        .pipe(
          tap(() => {
            this.handlePageEvent();
          }
          )
        )
        .subscribe();

    }, 1000);

  }

  onFilter(filterString: string) {
    this.filterBy.keyword = filterString;
    if (this.paginator) {
      this.paginator.pageIndex = 0;

      this.handlePageEvent();
    } else {
      console.error("Paginator is not defined.");
    }
  }

  private handlePageEvent() {
    if (this.cLearner) {
      this.getCurrentLernersdata(this.filterBy);
    } else {
      this.getHistoricLernersData(this.filterBy);
    }
  }

  private handlePage() {
    if (this.cLearner) {
      this.getCurrentLerners(this.filterBy.keyword);
    } else {
      this.getHistoricLerners(this.filterBy.keyword);
    }
  }


  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  tabChanged(event: MatTabChangeEvent): void {
    switch (event.index) {
      case 0:
        this.getCurrentLerners('currentLerners');
        break;
      case 1:
        this.getHistoricLerners('historicLerners');
        break;
    }
  }

  getCurrentLerners(currentLerners: string) {
    this.presentLeraner = "currentLerners";
    this.cLearner = true;
    this.filterBy.keyword = ''
    this.getCurrentLernersdata(this.filterBy);
  }

  getCurrentLernersdata(filterBy) {
    
    let currentPageIndex = 0;
    if (this.paginator) {
      currentPageIndex = this.paginator.pageIndex;
    }

    this.curiousLearnersService.findAllPaginated(`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.pageSize, filterBy)
      .subscribe(data => {
        this.dataSource.data = data.content;
        this.paginator.length = data.totalElements;
        this.dataSource.sort = this.sort;
      },
        error => {
          this.snackBarService.error(`${error.error.applicationMessage}`);
          this.router.navigate(['./curious-learner']);
        });


  }

  getHistoricLerners(historicLerners: string) {
    this.presentLeraner = historicLerners;
    this.cLearner = false;
    this.filterBy.keyword = ''
    this.paginator.pageIndex = 0;
    this.getHistoricLernersData(this.filterBy);

  }

  getHistoricLernersData(filterBy: { keyword: string; }) {

    let currentPageIndex = 0;
    if (this.paginator) {
      currentPageIndex = this.paginator.pageIndex;
    }
   

    this.curiousLearnersService.findAllHistoricalLerners(`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.pageSize, filterBy)
      .subscribe(data => {
        this.dataSource.data = data.content;
        this.paginator.length = data.totalElements;
        this.dataSource.sort = this.sort;
      },
        error => {
          this.snackBarService.error(`${error.error.applicationMessage}`);
          this.router.navigate(['./curious-learner']);
        });

  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }
}
