import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const dataInputRoute = 'finance';
@Injectable()
export class FinanceNavigation {

  constructor
    (
      private readonly route: ActivatedRoute
    ) { }
  financeSubMenu = {

    sectionName: 'Annual Delivery Plan',
    description: ``,
    state: null,
    icon: 'bar_chart',
    menuItems: [
      {
        name: 'Annual Delivery Plan',
        featureId: [108],
        description: ``,
        state: null,
        icon: null,
        submenu: [
          {
            name: 'Accredited Qualifications',
            featureId: [108],
            description: ``,
            state: `${dataInputRoute}/accredited-qualifications`,
            icon: null
          },
          {
            name: 'Service Lines',
            featureId: [108],
            description: ``,
            state: `${dataInputRoute}/service-lines`,
            icon: null
          },
          {
            name: 'Unaccredited Qualifications',
            featureId: [108],
            description: ``,
            state: `${dataInputRoute}/unaccredited-qualifications`,
            icon: null
          },
        ]
      },
    ]
  };
}
