<mat-card id='view-programmes'>
    <div>
        <mat-tab-group (selectedTabChange)="tabChanged($event)">
          <mat-tab label="Current Learners">
          </mat-tab>
          <mat-tab label="Historic Learners">
          </mat-tab>
        </mat-tab-group>
      </div>
      
    <br>
   
     

    <mat-card-header *ngIf="cLearner === true">
        <span class="search-container">
            <mat-form-field id="search_service_user_form" class="search_by_lr">
            <mat-label></mat-label>
              <input id="search_service_user_input" autocomplete="off" (keydown.enter)="onFilter(search_service_user_input.value)" matInput
                placeholder="Search by" #search_service_user_input aria-label="Search a user">
              <button id="do_search_button" mat-button matSuffix mat-icon-button aria-label="Search"
                (click)="onFilter(search_service_user_input.value)">
                <mat-icon>search</mat-icon>
              </button>
              <button id="clear_search_button" *ngIf="search_service_user_input.value !==''" mat-button matSuffix
                mat-icon-button aria-label="Clear" (click)="onFilter(''); search_service_user_input.value=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </span>

          <span class="spacer"></span>
       
    </mat-card-header>

    <mat-card-header *ngIf="cLearner === false">
        <span class="search-container">
            <mat-form-field id="search_service_user_form" class="search_by_lr">
            <mat-label></mat-label>
              <input id="search_service_user_input" autocomplete="off" (keydown.enter)="onFilter(search_service_user_input.value)" matInput
                placeholder="Search by" #search_service_user_input aria-label="Search a user">
              <button id="do_search_button" mat-button matSuffix mat-icon-button aria-label="Search"
                (click)="onFilter(search_service_user_input.value)">
                <mat-icon>search</mat-icon>
              </button>
              <button id="clear_search_button" *ngIf="search_service_user_input.value !==''" mat-button matSuffix
                mat-icon-button aria-label="Clear" (click)="onFilter(''); search_service_user_input.value=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </span>

          <span class="spacer"></span>
       
    </mat-card-header>
    
    <mat-card-content class="learners">

    <span class="spacer"></span>
    
    
        <mat-table id="learners_table" #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="DateofBirth">
                <mat-header-cell *matHeaderCellDef id="DateofBirth">
                    PRN
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">PRN:</span>
                    {{ element.prn || 'N/A' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef id="firstName" mat-sort-header>
                    First Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">First Name:</span>
                    {{ element.firstName || 'N/A'  }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastName" >
                <mat-header-cell *matHeaderCellDef id="lastName" mat-sort-header>
                    Last Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">Last Name:</span>
                    {{ element.lastName || 'N/A'  }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ULN">
                <mat-header-cell *matHeaderCellDef id="ULN">
                    ULN
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">ULN:</span>
                    {{ element.uln || 'N/A' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="establishmentId" *ngIf="!cLearner">
                <mat-header-cell *matHeaderCellDef id="establishmentId">
                    Current Location
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="mobile-label">Current Location</span>
                    {{ element.organizationName || 'N/A' }}
                </mat-cell>
            </ng-container>
            
            
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let element">

                    <button id="view_user_button_{{element.id}}" mat-icon-button color="primary"
                         matTooltip="View" [matTooltipShowDelay]="800"
                        [routerLink]="['./view']" [queryParams]="{id: element.id ,cLearner: cLearner, fname:element.firstName+' '+ element.lastName}">
                        <mat-icon>visibility</mat-icon>
                    </button>
                   
                  
            
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="cLearner ? displayedColumns : displayedHistoricColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: cLearner ? displayedColumns : displayedHistoricColumns;"></mat-row>
        </mat-table>
        <mat-paginator id="user_pagination" [pageSize]="pageSize" [showFirstLastButtons]='true' [hidePageSize]="true">
        </mat-paginator>
    </mat-card-content>
</mat-card>

