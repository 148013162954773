import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curious-set-up',
  templateUrl: './curious-set-up.component.html',
  styleUrls: ['./curious-set-up.component.scss']
})
export class CuriousSetUpComponent implements OnInit {

  constructor()
   {}

   ngOnInit() {
  }

}
